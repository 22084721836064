<template>
  <div class="dui_detail">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title="订单详情" left-arrow @click-left="toBack" :border="false" />
    <!-- 商品详情 -->
    <div class="duiIinfo">
      <div class="top">
        <div>商品信息</div>
        <div>{{orderDetail.status ==0?'待发货':'已完成' }}</div>
      </div>
      <div class="bottom">
        <div class="pic">
          <!-- <img src="../../../public/statics/images/index/1.jpg" alt /> -->
          <img :src="adddom(orderDetail.goods.thumb)" alt />
        </div>
        <div class="name">
          <div class="title">{{orderDetail.goods.title}}</div>
          <div class="ji">
            <span class="jinum">{{orderDetail.score}}积分</span>
            <span class="num">x1</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 配送信息 -->
    <div class="info psInfo">
      <div class="top">配送信息</div>
      <div class="bottom">
        <div class="line" v-show="orderDetail.address">
          <div class="lable">收货地址:</div>
          <div class="con">{{orderDetail.address}}</div>
        </div>
        <div class="line">
          <div class="lable">姓名电话:</div>
          <div class="con">{{orderDetail.speaker.name}} {{orderDetail.speaker.phone}}</div>
        </div>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="info orderInfo">
      <div class="top">订单信息</div>
      <div class="bottom">
        <div class="kuai">
          <div class="line">
            <div class="lable">订单编号:</div>
            <div class="con">{{orderDetail.order_sn}}</div>
          </div>
          <div class="line">
            <div class="lable">下单时间:</div>
            <div class="con">{{orderDetail.created_at}}</div>
          </div>
        </div>
        <div>
          <div class="line">
            <div class="lable">支付方式:</div>
            <div class="con">积分兑换</div>
          </div>
          <div class="line">
            <div class="lable">交易时间:</div>
            <div class="con">{{orderDetail.created_at}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 门票信息 -->
    <div class="info psInfo" v-if="orderDetail.stock">
      <div class="top">门票信息</div>
      <div class="bottom">
        <div class="line" v-if="orderDetail.stock.type == 1">
          <div class="lable">门票码:</div>
          <div class="con">{{orderDetail.stock && orderDetail.stock.source}}</div>
        </div>
        <div class="line" style="align-items: center;" v-if="orderDetail.stock.type == 2">
          <div class="lable">门票码:</div>
          <div class="con">
            <img @click="toImag(adddom(orderDetail.stock && orderDetail.stock.source))" style="width:60px;height:60px;" :src="adddom(orderDetail.stock && orderDetail.stock.source)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      orderid: "", //订单id
      orderDetail: {
        address: null,
        goods: {},
        speaker: {}
      } //订单详情
    };
  },
  created() {
    this.orderid = this.$route.query.id;
    this.getDetail();
    
  },
  methods: {
    toImag(src){
      ImagePreview({
        images: [
          src
          // 'https://img.yzcdn.cn/vant/apple-1.jpg',
          // 'https://img.yzcdn.cn/vant/apple-2.jpg',
        ],
        // startPosition: 1,
      });
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    toBack() {
      this.$router.go(-1);
    },
    getDetail() {
      var that = this;
      var params = {
        token: Cookies.get("spToken"),
        id: that.orderid
      };
      that.$api.integral.orderDetail(params).then(res => {
        if (res.code == 200) {
          that.orderDetail = res.result;
        }
      });
    }
  }
};
</script>

<style>
</style>